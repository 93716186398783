.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.items {
  margin-top: 20px;
  font-size: 18px;
  color: #549bcf;
  margin-left: 20px;
}

.items .text {
  cursor: pointer;
}

.small {
  height: 20px;
}
