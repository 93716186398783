.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
}

.grid div:nth-child(1) {
  width: 60%;
}
.grid div:nth-child(2) {
  width: 40%;
}
.grid div:nth-child(3) {
  width: 50%;
}
.grid div:nth-child(4) {
  width: 50%;
}
.grid div:nth-child(5) {
  width: 60%;
}
.grid div:nth-child(6) {
  width: 70%;
}

.small {
  height: 20px;
}
