.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.contents {
  display: flex;
  justify-content: space-between;
}

.header {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.grid {
  border: 3px solid black;
  width: 150px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}

.grid div {
  max-width: 85%;
}
