.root {
  padding: 10px;
}

.root img {
  margin-top: 20px;
}

.dropdown {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
