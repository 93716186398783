.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.box {
  display: flex;
  border: 5px solid #368fcb;
  border-radius: 10px;
  width: 95%;
  margin-top: 50px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.title {
  font-weight: bold;
}

.left .title {
  display: inline-block;
}

.text {
  margin-top: 20px;
}

.image {
}

.image img {
  width: 100%;
}
