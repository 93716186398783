.root {
}

.title {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.donkey {
  width: 575px;
  height: 406px;
}

.donkey img {
  width: 100%;
}
