.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.background {
  position: absolute;
  background: linear-gradient(
    180deg,
    #449dac 0%,
    rgba(255, 255, 255, 0.5) 99.99%,
    #fff 100%
  );
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
}

.top {
  position: fixed;
  left: -30px;
  right: -30px;
  top: 0;
  height: 125px;
}

.logo {
  width: 120px;
  height: 33px;
  left: 20px;
  top: 40px;
  position: fixed;
}

.logo img {
  width: 100%;
}

.options {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 75px;
  margin-top: 15px;
}

.options .messages {
}

.options .help {
}

.options .logout {
  cursor: pointer;
  text-decoration: underline;
  color: #131313;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
  margin-top: 4px;
}

.title {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  font-family: Open Sans;
}

.bottom {
  position: absolute;
  top: 800px;
  left: 0;
  right: 0;
  background-image: url(https://practice.gogetwaggle.com/student/app/static/media/dome-classview.svg);
  background-position: top;
  padding-bottom: 50px;
  height: 469px;
}

.planet {
  position: absolute;
  width: 225px;
  height: 225px;
  right: -50px;
  top: 300px;
}
