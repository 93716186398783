.root {
  width: 120px;
  display: flex;
}

.sp {
  width: 175px;
  display: flex;
}

.icon {
  width: 32px;
  height: 32px;
  opacity: 0.6;
  margin-right: 10px;
}

.icon img {
  width: 100%;
}

.text {
  font-family: Open Sans;
  color: #464646;
  opacity: 0.6;
  font-size: 18px;
  font-weight: 700;
  margin-top: 3px;
}
