.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
}

.header .title {
  font-family: "Zilla Slab";
  font-size: 32px;
  color: #056b90;
  margin-top: -5px;
  margin-left: 10px;
  font-weight: bold;
}

.contents {
  margin-top: 10px;
  margin-left: 50px;
  width: 85%;
  font-size: 18px;
  font-family: "Open Sans";
}

.contents > div {
  margin-bottom: 20px;
}

.contents li {
  margin-bottom: 25px;
}
