.root {
}

.title {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
}

.image {
  width: 100%;
}

.image img {
  width: 100%;
}

.box {
  border: 1px solid black;
  padding: 10px;
}

.box .subtitle {
  margin-top: 0px;
  margin-left: 0px;
}

.box li {
  margin-bottom: 10px;
}
