.root {
  padding-inline-start: 20px;
  margin-block-start: 10px;
  list-style-type: disc;
}

.root > li {
  margin-left: 10px;
  margin-bottom: 10px;
}

.root > li::marker {
  color: #056b90;
  font-weight: bold;
}
