.root {
  width: 905px;
  height: 475px;
  margin-left: auto;
  margin-right: auto;

  background: #fbfcfd;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-family: Open Sans;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  height: Calc(100% - 45px);
  justify-content: space-between;
}
