.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popup {
  position: absolute;
  width: 620px;
  height: 400px;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  left: 50%;
  top: 200px;
  transform: translateX(Calc(-50% - 142.5px));
}

.header {
  display: flex;
  justify-content: space-between;
}

.contents {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}
