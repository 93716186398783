.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-top: 40px;
  width: 120px;
  height: 120px;
  display: grid;
  grid-template-rows: 10% 10%;
  grid-template-columns: 30% 30%;
  gap: 55px;
}
