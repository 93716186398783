.root {
  position: absolute;
  min-width: 130px;
  height: 32px;
  left: 0px;
  top: 110px;
  background: #fbfcfd;
  border: 0px;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #131313;
}
