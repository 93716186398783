.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding: 10px;
}
.contents {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.row {
  border: 6px solid #549bcf;
  box-sizing: border-box;
  font-size: 14px;
}

.row:nth-child(1) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.row:nth-child(2) {
  border-top: none;
  border-bottom: none;
  border-left: 6px solid #549bcf;
  border-right: 6px solid #549bcf;
}

.row:nth-child(3) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.title {
  width: 200px;
  height: 25px;
  color: white;
  font-weight: bold;
  background: #549bcf;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
}

.text {
  padding: 10px;
}
