.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.background {
  position: absolute;
}

.top {
  width: 70%;
  height: 190px;
  margin-left: 100px;
  margin-top: 125px;
  position: absolute;
}

.top .text {
  font-size: 18px;
  line-height: 18px;
}

.top .line {
  margin-top: 15px;
  display: flex;
}

.top .line .dropdown {
  margin-top: -4px;
  margin-left: 3px;
}

.equation {
  margin-top: 40px;
  display: flex;
}

.equation .text {
  font-size: 18px;
  line-height: 18px;
  margin-right: 5px;
}

.input {
  width: 30px;
  height: 20px;
  margin-top: -2px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0px solid transparent;
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.fraction {
  width: 43px;
  margin-top: -20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.fraction .line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 5px;
  margin-bottom: 7px;
}

.fraction .input {
  margin-left: 5px;
}
