.root {
  width: 100%;
  height: 100vh;
  background-image: url(https://practice.gogetwaggle.com/student/public/images/default-background.svg);
  position: relative;
  background-size: cover;
  overflow: hidden;
  margin: 0 auto;
  font-family: Open Sans;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 100;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #131313;
  line-height: 33px;
  margin-top: 10px;
}

.dots {
  top: Calc(-50vh - 75px);
  position: absolute;
  width: 100%;
  height: 100vh;
  border: 2px dotted #ffffff;
  border-radius: 0 0 51% 49%;
  z-index: 10;
}

.circle {
  width: 220px;
  height: 220px;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%);
  margin-top: -240px;
  position: absolute;
  z-index: 5;
  border: 3px solid #fff;
  border-radius: 50%;
}

.selected {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%);
  margin-top: -180px;
  width: 184px;
  height: 184px;
  z-index: 10;
  background: white;
  border-radius: 50%;
}

.selected img {
  width: 276px;
  height: 184px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.left {
  position: absolute;
  left: 25%;
  transform: translateX(-50%);
  top: Calc(50vh - 200px);
  width: 125px;
  height: 125px;
  z-index: 10;
  background: white;
  border-radius: 50%;
}

.left img {
  width: 168px;
  height: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;
}

.right {
  position: absolute;
  left: 75%;
  transform: translateX(-50%);
  top: Calc(50vh - 200px);
  width: 125px;
  height: 125px;
  z-index: 10;
  background: white;
  border-radius: 50%;
}

.right img {
  width: 168px;
  height: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;
}

.world {
  min-width: 179px;
  height: 40px;
  color: #056b90;
  margin-top: 10px;
  background: #fbfcfd;
  box-sizing: border-box;
  border-radius: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  border: 2px solid #07758d;
  background: white;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.line {
  position: absolute;
  width: 3px;
  height: 52%;
  top: 47%;
  bottom: -1000px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
}

.planet {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 504px;
  height: 112px;
}

.box {
  position: absolute;
  background-color: white;
  margin: 0 auto;
  border-radius: 8px;
  padding: 10px;
  color: #333333;
  z-index: 16;
  width: 600px;
  height: 200px;

  left: 50%;
  transform: translateX(-50%);
  top: Calc(50vh + 50px);
}

.box .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.box .desc {
  font-size: 18px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.box .button {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  border: none;
  cursor: pointer;
  width: 188px;
  height: 40px;
  background: #07758d;
  border-radius: 20px;
  color: #fbfcfd;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
}
