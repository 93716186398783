.root {
  position: relative;
}

.outter {
  position: absolute;
  width: 272px;
  height: 272px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  transform: translateX(-50%);
}

.inner {
  position: absolute;
  width: 240px;
  height: 240px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-family: "Open Sans";
  color: #131313;
  top: 20px;
  transform: translateX(-50%);
}

.contents {
  padding-top: 20px;
  box-sizing: border-box;
}

.contents .title {
  font-size: 24px;
  font-weight: bold;
}

.contents .collected {
  font-size: 18px;
}

.contents .value {
  font-size: 36px;
  font-weight: bold;
  color: #131313;
  text-align: center;
}

.contents .points {
  font-size: 18px;
}

.contents .gems {
  display: flex;
  justify-content: center;
}
