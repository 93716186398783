.root {
  background: #fff;
  height: 48px;
  border-radius: 24px;
  width: 60%;
  position: relative;
}

.bar {
  position: relative;
  height: 10px;
  width: 97.5%;
  margin-left: 1.25%;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.fill {
  background: #93b962;
  height: 100%;
  border-radius: 24px;
}

.track {
  position: relative;
  width: 95%;
  background: red;
}

.thumb {
  width: 42px;
  height: 80px;
  margin-top: -55px;
  position: absolute;
}

.thumb img {
  width: 100%;
}
