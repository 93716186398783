.root {
  position: absolute;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50%;
}

.background {
  width: 100%;
  height: 97px;
}
