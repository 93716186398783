.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}

.contents {
  width: 100%;
  height: 200px;
  margin-top: 25px;
}

.contents:nth-child(1) {
  display: flex;
}

.box {
  border: 4px solid #1588c9;
  box-sizing: border-box;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 100%;
}

.box .text {
  width: 100%;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
  margin-top: -30px;
  text-align: center;
  font-size: 14px;
}

.box .bucket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}

.contents:nth-child(2) {
  margin-top: 50px;
}

.row {
  display: flex;
  margin-bottom: 30px;
}
