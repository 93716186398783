.root {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
  display: flex;
}

.root .selector {
  margin-top: 5px;
  width: 30px;
  height: 30px;
  background-color: white;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-family: Open Sans;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.root .selector:hover,
.root .selector.selected {
  font-weight: bold;
  border: 1px solid black;
}
