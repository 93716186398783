.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 18px;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
}

.grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 50%;
  gap: 20px;
  height: 160px;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

.shape {
  width: 100%;
  position: relative;
}

.shape img {
  width: 100%;
}

.shape .highlight {
  width: 100%;
  height: 100%;
  background: rgba(75, 236, 230, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
