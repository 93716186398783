@font-face {
  font-family: "Soleil";
  src: local("Soleil"), url("./fonts/Soleil.otf") format("opentype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("../../common/fonts/Proxima Nova Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("../../common/fonts/Proxima Nova Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("OpenSans-Regular"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Zilla Slab";
  src: local("ZillaSlab-Regular"),
    url("./fonts/ZillaSlab-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AudioWide";
  src: local("AudioWide-Regular"),
    url("./fonts/Audiowide-Regular.ttf") format("truetype");
}
