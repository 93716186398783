.root {
  width: 100%;
  height: 100%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.hextop {
  margin-top: 5px;
  width: 0;
  height: 0;
  border-bottom: 36px solid #eeeeee;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
}

.hex {
  width: 120px;
  height: 72px;
  background-color: #eeeeee;
  position: relative;
}

.hexbottom {
  width: 0;
  border-top: 36px solid #eeeeee;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  margin-bottom: 20px;
}

.name {
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}

.progress {
}

.progress .bar {
  width: 72px;
  background: #eeeeee;
  border-radius: 4px;
  margin-bottom: 5px;
}

.progress .bar .fill {
  border-radius: 4px;
  background: #96ba63;
}

.text {
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
}

.icon {
  margin-top: -5px;
}

.disabled .icon {
  filter: grayscale(100%);
  opacity: 0.3;
}
