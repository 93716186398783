.root {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.background {
  width: 64px;
  height: 64px;
  background: #edeff0;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0 auto;
  border: 2px solid transparent;
}

.icon {
  margin-left: 11px;
  margin-top: 7px;
}

.image {
  width: 100%;
}

.text {
  font-family: Open Sans;
  font-size: 18px;
  text-align: center;
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.root:hover .background {
  background: #ccecf2;
}

.root:hover .text {
  font-weight: bold;
}

.root.selected .background {
  background: #ccecf2;
  border: 2px solid #056b90;
}

.root.selected .text {
  font-weight: bold;
}
