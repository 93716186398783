.root {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
}

.root img {
  width: 45%;
}

.empty {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.fill {
  background-color: rgba(0, 0, 0, 1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
