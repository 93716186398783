.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.items {
  margin-top: 20px;
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.large,
.medium,
.small {
  cursor: pointer;
}

.small {
  height: 20px;
}
