.root {
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.root img {
  width: 75%;
}

.empty {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}
