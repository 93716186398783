.logout {
  cursor: pointer;
  text-decoration: underline;
  color: #131313;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
