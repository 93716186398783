.root {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
}
