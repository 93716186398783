.root {
  position: relative;
  font-size: 16px;
  height: 100%;
}

.title {
  margin-left: 20px;
  margin-top: 20px;
}

.header {
  display: flex;
  margin-left: 100px;
}

.image {
  margin-top: 20px;
  margin-left: 20px;
  width: 200px;
}

.image img {
  width: 100%;
}

.header .entry {
  margin-top: 100px;
}

.answers {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.entry {
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
}

.text {
  margin-left: 10px;
  margin-right: 10px;
}

.bucket {
  width: 50px;
  height: 35px;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -15px;
}

.bucket .contents {
  width: 100%;
  height: 19px;
}
