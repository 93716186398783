.root {
  color: #282828;
  font-size: 14px;
  line-height: 20px;
}

.circle {
  background: black;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  margin-top: -2px;
  display: inline-block;
}

.text > div {
  margin-bottom: 10px;
}
