.root {
  position: relative;
  font-size: 18px;
}

.title {
  margin-left: 20px;
  margin-top: 20px;
}

.image {
  margin-top: 20px;
  margin-left: 20px;
  width: 90%;
}

.image img {
  width: 100%;
}

.answers {
  display: flex;
  flex-direction: column;
}

.entry {
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
}

.text {
  margin-left: 10px;
  margin-right: 10px;
}

.bucket {
  width: 100px;
  height: 35px;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -15px;
}

.bucket .contents {
  width: 100%;
  height: 19px;
}
