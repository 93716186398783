.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.instructions {
  margin-top: 20px;
  font-size: 16px;
}

.list {
  margin-top: 20px;
}

.row {
  margin-top: 20px;
}

.dropdown {
  display: inline-block;
}
