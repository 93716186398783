.root {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 20px;
  cursor: pointer;
  text-align: center;
  color: #549bcf;
}

.contents {
  margin-top: -4px;
}
