.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}

.contents {
  display: flex;
  width: 100%;
  height: 200px;
  margin-top: 25px;
}

.box {
  border: 6px solid #1588c9;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.box:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.box:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.box .text {
  width: 100%;
  height: 50%;
  padding: 25px 10px 10px 10px;
  box-sizing: border-box;
  border-bottom: 2px solid #1588c9;
}

.box .bucket {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contents:nth-child(2) {
  margin-top: 50px;
}
