.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}

.contents {
  display: flex;
  width: 100%;
  height: 200px;
  margin-top: 25px;
}

.box {
  border: 6px solid #1588c9;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.box:nth-child(1) {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.box:nth-child(2) {
  border-left: none;
  border-right: none;
}

.box:nth-child(3) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.box .text {
  width: 80%;
  height: 45%;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
  border-bottom: 2px solid #1588c9;
  background: #1588c9;
  color: white;
  margin-top: -2px;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box .bucket {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}

.contents:nth-child(2) {
  margin-top: 50px;
}
