.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.mask {
  background: #000000;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
}

.popup {
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
}

.popup .left {
  width: 45%;
  height: 100%;
  background: url(https://practice.gogetwaggle.com/student/public/images/world1.svg);
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.popup .left .contents {
  width: 50%;
  height: 90%;
  margin: 0;
  justify-content: center;
  padding-top: 25px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.popup .right {
  padding-left: 20px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.name {
  text-align: center;
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
}

.progress {
}

.progress .bar {
  width: 72px;
  background: #eeeeee;
  border-radius: 4px;
  margin-bottom: 5px;
}

.progress .bar .fill {
  border-radius: 4px;
  background: #96ba63;
}

.text {
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  margin-top: 10px;
}

.icon {
  margin-top: -15px;
}

.title {
  font-family: Open Sans;
  font-size: 32px;
  color: #131313;
  margin-bottom: 28px;
}

.right .contents {
  font-family: Open Sans;
  font-size: 16px;
  color: #131313;
}
