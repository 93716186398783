.root {
  width: 140px;
  height: 28px;
  background: #fff;
  border-radius: 28px;
  display: flex;
  padding: 10px;
}

.text {
  font-family: Open Sans;
  margin-top: 2px;
  margin-left: 10px;
  color: #777777;
  text-align: right;
  width: 100%;
  margin-right: 5px;
}

.left {
  position: absolute;
  width: 66px;
  height: 66px;
  padding: 2px;
  background-color: white;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  box-sizing: border-box;
}

.meter {
  background-color: rgba(0, 0, 0, 0.075);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.fill {
  position: absolute;
  margin-left: 6px;
  margin-top: 6px;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.gem {
  width: 60%;
  height: 60%;
  height: 60%;
  margin-top: -155px;
  margin-left: 13px;
}

.gem img {
  width: 100%;
}
