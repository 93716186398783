.root {
}

.background {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url("https://practice.gogetwaggle.com/student/public/images/world1.svg");
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
}

.overlays {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
}

.overlayleft {
  overflow: hidden;
  min-width: 400px;
  max-width: 744px;
  width: 30%;
  height: 100%;
  opacity: 1;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.overlayleft img {
  width: 100%;
  height: 100%;
}

.overlayright {
  overflow: hidden;
  height: 100%;
  opacity: 1;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  margin-left: -2px;
}

.overlayright img {
  width: 100%;
  height: 100%;
}

.avatar {
  position: absolute;
  left: 50%;
  height: 75%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  z-index: 100;
}

.avatar > img {
  height: 100%;
}

.top {
  position: fixed;
  left: -30px;
  right: -30px;
  top: 0;
  height: 125px;
}

.options {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 75px;
  margin-top: 15px;
}

.options .messages {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzIDJIMUMwLjQgMiAwIDIuNCAwIDNWNkMwIDYuNCAwLjIgNi42OTk5OSAwLjUgNi44OTk5OUwxMS41IDEyLjlDMTEuNyAxMyAxMS44IDEzIDEyIDEzQzEyLjIgMTMgMTIuMyAxMyAxMi41IDEyLjlMMjMuNSA2Ljg5OTk5QzIzLjggNi42OTk5OSAyNCA2LjQgMjQgNlYzQzI0IDIuNCAyMy42IDIgMjMgMloiIGZpbGw9IiMxMzEzMTMiLz4KPHBhdGggZD0iTTEzLjQgMTQuNkMxMyAxNC45IDEyLjUgMTUgMTIgMTVDMTEuNSAxNSAxMSAxNC45IDEwLjYgMTQuNkwwIDguOTAwMDJWMjFDMCAyMS42IDAuNCAyMiAxIDIySDIzQzIzLjYgMjIgMjQgMjEuNiAyNCAyMVY4LjkwMDAyTDEzLjQgMTQuNloiIGZpbGw9IiMxMzEzMTMiLz4KPC9zdmc+Cg==)
    no-repeat center center;
  cursor: pointer;
  padding: 4px 0px 0px 20px;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.options .help {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDBDNS4zNzYgMCAwIDUuMzc2IDAgMTJDMCAxOC42MjQgNS4zNzYgMjQgMTIgMjRDMTguNjI0IDI0IDI0IDE4LjYyNCAyNCAxMkMyNCA1LjM3NiAxOC42MjQgMCAxMiAwWk0xMy4yIDIwLjRIMTAuOFYxOEgxMy4yVjIwLjRaTTE1LjY4NCAxMS4xTDE0LjYwNCAxMi4yMDRDMTMuNzQgMTMuMDggMTMuMiAxMy44IDEzLjIgMTUuNkgxMC44VjE1QzEwLjggMTMuNjggMTEuMzQgMTIuNDggMTIuMjA0IDExLjYwNEwxMy42OTIgMTAuMDkyQzE0LjEzNiA5LjY2IDE0LjQgOS4wNiAxNC40IDguNEMxNC40IDcuMDggMTMuMzIgNiAxMiA2QzEwLjY4IDYgOS42IDcuMDggOS42IDguNEg3LjJDNy4yIDUuNzQ4IDkuMzQ4IDMuNiAxMiAzLjZDMTQuNjUyIDMuNiAxNi44IDUuNzQ4IDE2LjggOC40QzE2LjggOS40NTYgMTYuMzY4IDEwLjQxNiAxNS42ODQgMTEuMVoiIGZpbGw9IiMxMzEzMTMiLz4KPC9zdmc+Cg==)
    no-repeat center center;
  cursor: pointer;
  padding: 4px 0px 0px 20px;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.options .logout {
  cursor: pointer;
  text-decoration: underline;
  color: #131313;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
  margin-top: 4px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 200px;
  left: 50px;
  width: 50px;
}

.sidebar .item {
  width: 50px;
  height: 50px;
}

.sidebar .text {
  text-align: center;
  margin-bottom: 10px;
}

.bottom {
  position: fixed;
  left: -30px;
  right: -30px;
  bottom: 0;
  height: 125px;
}

.footer {
  background-size: 100%;
  height: 75px;
  bottom: 0;
  position: absolute;
  left: 22%;
  right: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer > img {
  width: 100%;
}

.header {
  background-size: 100%;
  width: 100%;
  height: 100%;
  top: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 30px;
  top: 0px;
}

.header > img {
  width: 100%;
}

.header .content {
  position: absolute;
  display: flex;
  top: 11vh;
  bottom: 15%;
  left: 25px;
  right: 50px;
  font-family: Open Sans;
  justify-content: center;
  background: #bfdee6;
  padding: 10px;
}

.inner {
  background: #89c2ca;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.row {
  display: flex;
  overflow-x: hidden;
}

.item {
  margin-right: 10%;
  width: 110px;
  height: 110px;
}

.al {
  position: absolute;
  top: 85px;
  left: 0px;
  width: 25px;
}

.ar {
  position: absolute;
  top: 85px;
  right: 0px;
  width: 25px;
}

.inner > div:nth-child(2) .al {
  top: 180px;
}

.inner > div:nth-child(2) .ar {
  top: 180px;
}

.dot {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 10%;
  border-radius: 10px;
  margin-top: 20px;
}

.timer {
  position: absolute;
  bottom: 25px;
  left: 200px;
  display: flex;
  background: white;
  border-radius: 5px;
}

.timer .text {
  color: #ff0303;
  font-size: 30px;
}

.button {
  position: absolute;
  bottom: 25px;
  left: Calc(50% - 25px);
  transform: translateX(-50%);
  background: #07758d;
  border-radius: 25px;
  text-align: center;
  color: white;
  font-size: 20px;
  padding: 10px;
  width: 75px;
  cursor: pointer;
  z-index: 999999;
}
