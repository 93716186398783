.root {
  width: 100%;
  height: 100%;
}

.background {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url("https://practice.gogetwaggle.com/student/public/images/world1.svg");
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
}

.contents {
  margin-top: 5px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.contents .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.contents .header .left {
  display: flex;
}

.contents .header .left .home {
  width: 44px;
  text-align: center;
  height: 44px;
  border-radius: 50%;
  background-color: #fbfcfd;
  line-height: 40px;
  margin-right: 25px;
  cursor: pointer;
}

.contents .header .left .home img {
  width: 65%;
  margin-top: 7px;
}

.contents .header .left .logo {
  margin-top: 5px;
}

.contents .header .right {
  margin-right: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.contents .progress {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.indev {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: bold;
  color: black;
  top: 200px;
}
