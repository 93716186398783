.root {
  width: 140px;
  height: 28px;
  background: #fff;
  border-radius: 28px;
  display: flex;
  padding: 10px;
}

.plus {
  width: 28px;
  height: 28px;
}

.plus img {
  width: 100%;
}

.text {
  font-family: Open Sans;
  margin-top: 2px;
  margin-left: 10px;
}
