.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
}

.popup {
  background-color: #fff;
  margin-top: 250px;
  padding: 20px;
  color: black;
  border-radius: 8px;
  overflow: auto;
  height: 100px;
  width: 300px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(-50%);
}

.text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-family: Open Sans;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  margin-left: auto;
  margin-right: auto;
  width: 116px;
  height: 40px;
  background: #07758d;
  border-radius: 20px;
  vertical-align: middle;
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fbfcfd;
  padding: 0 10px;
  cursor: pointer;
}
