.root {
  position: relative;
  font-size: 16px;
  height: 100%;
}

.title {
  margin-left: 20px;
  margin-top: 20px;
}

.header {
  display: flex;
  margin-left: 100px;
}

.image {
  margin-top: 20px;
  margin-left: 20px;
  width: 300px;
}

.image img {
  width: 100%;
}

.header .entry {
  margin-top: 100px;
}

.text {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 10px;
}

.bucket {
  width: 150px;
  height: 35px;
  border-bottom: 2px solid black;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -15px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.bucket .contents {
  width: 100%;
  height: 19px;
}

.ab {
  width: 40px;
}

.ab img {
  margin-top: -10px;
  width: 100%;
}
