.root {
  width: 100%;
  height: 100%;
}

.background {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url("https://practice.gogetwaggle.com/student/public/images/world1.svg");
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
}

.progress {
  margin-left: 50px;
  margin-right: 50px;
}

.progress .left {
  padding: 8px 18px;
  margin: 0;
  list-style-type: none;
  vertical-align: middle;
  background: #ffffff;
  border-radius: 24px;
  width: 430px;
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
}

.progress .left > span {
  line-height: 30px;
}

.progress .left .arrow {
  margin-top: 4px;
  height: 24px;
  cursor: pointer;
}

.progress .left .arrow.prev {
  margin-left: 10px;
}

.progress .left .arrow.next {
  margin-left: 10px;
}

.progress .left .lessons {
  display: flex;
}

.progress .right {
  padding: 8px 18px;
  margin: 0;
  list-style-type: none;
  vertical-align: middle;
  background: #ffffff;
  border-radius: 24px;
  width: 300px;
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
}

.progress .right > span {
  line-height: 30px;
}

.contents {
  margin-top: 5px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.contents .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.contents .header .left {
  display: flex;
}

.contents .header .left .home {
  width: 44px;
  text-align: center;
  height: 44px;
  border-radius: 50%;
  background-color: #fbfcfd;
  line-height: 40px;
  margin-right: 25px;
  cursor: pointer;
}

.contents .header .left .home img {
  width: 65%;
  margin-top: 7px;
}

.contents .header .left .logo {
  margin-top: 5px;
}

.contents .header .right {
  margin-right: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.contents .progress {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
