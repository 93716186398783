.root {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: #fbfcfd;
  border-radius: 0px 48px 48px 0px;
  padding: 10px 5px;
  box-sizing: border-box;
  width: 100px;
  max-width: 100px;
}
