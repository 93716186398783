.root {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f4ec;
  min-height: 83px;
  border-top: 8px solid #fcbe39;
  display: flex;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 15px;
  z-index: 999999999;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: -480px;
  bottom: 0;
  z-index: 0;
}

.icon {
  margin-right: 20px;
  z-index: 1;
}

.text {
  font-weight: bold;
  color: #131313;
  opacity: 0.8;
  white-space: nowrap;
  margin-right: 20px;
  z-index: 1;
}

.contents {
  width: 100%;
  z-index: 1;
}

.close {
  width: 13px;
  height: 13px;
  margin-left: 50px;
  z-index: 1;
}
