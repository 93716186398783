.root {
  display: flex;
  font-size: 18px;
  margin-bottom: 10px;
}

.index {
  text-align: right;
  width: 20px;
  margin-right: 10px;
}

.contents {
  width: 100%;
}
