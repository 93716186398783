.root {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.highlight {
  width: 100%;
  height: 100%;
  background: rgba(75, 236, 230, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
