.root {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.background {
  position: absolute;
}

.top {
  width: 100%;
  height: 190px;
  position: absolute;
  padding-top: 10px;
  padding-left: 20px;
}

.top .text {
  font-size: 18px;
  line-height: 18px;
}

.equation {
  margin-top: 300px;
  width: 100%;
}

.equation .text {
  font-size: 18px;
  line-height: 18px;
  margin-right: 5px;
}

.dropdown {
  display: inline-block;
  width: 120px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.dropdown > div {
  transform: translateY(-2px);
}

.inputs {
  width: 200px;
  height: 150px;
  position: absolute;
  display: grid;
  gap: 13px;
  grid-template-rows: 15% 15%;
  grid-template-columns: 50% 50%;
  top: 149px;
  left: 300px;
}

.inputs input {
  width: 25px;
  height: 20px;
  margin-top: -2px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0px solid transparent;
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  pointer-events: all;
}

.inputs input:focus {
  outline: none;
}
