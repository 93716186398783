.root {
  background: #ffbe2e;
  border: 4px solid #ffbe2e;
  width: 264px;
  height: 400px;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  margin: 0px 10px 15px;
  cursor: pointer;
  color: #131313;
}

.inner {
  background-color: #fff;
  width: 240px;
  height: 376px;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: Open Sans;
  color: #131313;
  padding: 11px 12px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.top .name {
  font-size: 19px;
  font-weight: bold;
}

.top .score .title {
  font-size: 16px;
}

.top .score .title {
  font-size: 16px;
  font-weight: bold;
}

.footer {
  display: flex;
}

.footer .icon {
  margin-right: 20px;
}

.footer .type {
  margin-top: 5px;
  font-size: 20px;
}
