.root {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
}

.root img {
  width: 100%;
}

.contents {
  cursor: pointer;
}
