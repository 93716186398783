.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.contents {
  margin-top: 10px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contents div {
  margin-bottom: 20px;
}
