.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
}

.small {
  height: 20px;
}
