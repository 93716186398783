.root {
  background-color: rgba(68, 157, 172, 0.5);
  padding: 5px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.title {
  font-size: 24px;
  color: #131313;
  font-family: Open Sans;
  font-weight: bold;
  margin-bottom: 5px;
}
