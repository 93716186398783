.root {
  margin-left: 15px;
  display: flex;
  height: 100%;
}

.text {
  margin-top: 2px;
  color: #056b90;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-weight: bold;
}
