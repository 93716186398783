.root {
  color: #282828;
  font-size: 14px;
}

.contents {
  font-size: 13px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.text div {
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.image0 {
  position: absolute;
  top: -256px;
  left: 490px;
  pointer-events: none;
}

.underline0 {
  position: absolute;
  top: -353px;
  left: 55px;
}

.image1 {
  position: absolute;
  top: -180px;
  left: 80px;
  transform: rotateZ(-110deg);
  pointer-events: none;
}

.image2 {
  position: absolute;
  top: -160px;
  left: 540px;
  transform: rotateZ(-45deg);
  pointer-events: none;
}

.image0 img,
.image1 img,
.image2 img {
  width: 100%;
}

.arrows {
  position: relative;
  top: -10px;
  left: -20px;
  margin-right: -25px;
}

.arrows span:nth-child(1) {
  margin-right: -2px;
}

.arrows span:nth-child(2) {
  margin-left: -2px;
}

.arrows:nth-child(1) {
  left: -21px;
}

.arrows:nth-child(2) {
  left: -20px;
}

.arrows:nth-child(3) {
  left: -22px;
}
