.root {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #fbfcfd;
  margin-left: 10px;
  margin-top: -3px;
  background: #fbfcfd;
  cursor: pointer;
  box-sizing: border-box;
}

.root:hover {
  border: 1px solid #c6cace;
}

.root.selected {
  border: 2px solid #ffbe2e;
}

.text {
  line-height: 32px;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
}
