.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-top: 20px;
  display: grid;
  width: 200px;
  grid-template-rows: 45% 45%;
  grid-template-columns: 45% 45%;
  gap: 25px;
  margin-left: 30px;
}

.grid div:nth-child(1) img,
.grid div:nth-child(3) img {
  margin-top: 20px;
}

.large,
.medium,
.small {
  cursor: pointer;
}

.small {
  height: 20px;
}
