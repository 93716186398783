.root {
  color: #282828;
  font-size: 14px;
}

.contents {
  font-size: 13px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.text div {
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.image0 {
  position: absolute;
  top: -145px;
  left: 450px;
}

.image1 {
  position: absolute;
  top: -100px;
  left: 450px;
}
.image2 {
  position: absolute;
  top: -55px;
  left: 450px;
}

.image0 img,
.image1 img,
.image2 img {
  width: 100%;
}
