.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
}

.header .title {
  font-family: "Zilla Slab";
  font-size: 32px;
  color: #056b90;
  margin-top: -5px;
  margin-left: 10px;
  font-weight: bold;
}

.contents {
  margin-top: 10px;
  margin-left: 50px;
  width: 85%;
  font-size: 18px;
  font-family: "Open Sans";
}

.contents > div {
  margin-bottom: 20px;
}

.contents .instructions {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contents .image {
  width: 180px;
  margin-left: 150px;
}

.contents .title {
  font-family: "Zilla Slab";
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contents .details {
  font-family: "Zilla Slab";
  font-size: 18px;
  margin-bottom: 20px;
}
