.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding: 10px;
}

.image {
  width: 100%;
}

.image img {
  width: 100%;
}

.contents {
  display: grid;
  grid-template-rows: 45% 45%;
  grid-template-columns: 45% 45%;
  margin-left: 45px;
  margin-top: 25px;
  gap: 10px;
  width: 95%;
  height: 94%;
}
