.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.top {
  display: flex;
  width: 100%;
  height: 190px;
}

.top .instructions {
  width: 40%;
  margin-right: 50px;
}

.top .instructions .text {
  font-size: 18px;
}

.top .instructions .text:first-child {
  margin-bottom: 10px;
}

.top .image {
}

.top .image img {
  width: 100%;
}

.header {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.grid {
  margin-top: 10px;
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 30% 10% 10% 10% 10% 10% 10% 10%;
  width: 100%;
  height: 160px;
  font-size: 22px;
}

.grid .text {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  line-height: 40px;
  text-indent: 10px;
  box-sizing: content-box;
}

.grid .icon {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  line-height: 40px;
  text-indent: 10px;
  box-sizing: content-box;
}

.grid .icon img {
  width: 45%;
  margin-left: 5px;
  margin-top: 2px;
}

.bottom {
  display: flex;
  font-size: 18px;
  margin-top: 10px;
}

.bottom .key {
  font-weight: bold;
  margin-right: 5px;
}

.bottom .image {
  margin-top: -6px;
}
