.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
  margin-left: 10px;
}

.dropdown {
  display: inherit;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
