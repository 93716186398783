.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-top: 40px;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: 45% 45%;
  grid-template-columns: 45% 45%;
  gap: 25px;
}
