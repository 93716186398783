.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}

.contents {
  width: 100%;
  height: 150px;
  margin-top: 25px;
  margin-left: 50px;
}

.contents:nth-child(2) {
  width: 90%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.box {
  border: 6px solid #1588c9;
  box-sizing: border-box;
  width: 40%;
  height: 150px;
  border-radius: 15px;
}

.box .header {
  width: 30%;
  height: 20px;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
  border-bottom: 2px solid #1588c9;
  background: #1588c9;
  color: white;
  margin-top: -2px;
  margin-left: -1px;
  text-align: center;
  font-weight: bold;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box .text {
  margin-left: 5px;
}

.box .bucket {
  width: 100%;
  height: 80%;
  padding: 5px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.arrow {
  width: 30px;
  margin-left: -1px;
  margin-right: -1px;
}

.arrow img {
  margin-top: 50px;
  width: 100%;
}
