.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}

.contents {
  display: flex;
  width: 100%;
  height: 125px;
  margin-top: 25px;
}

.contents:nth-child(2) {
  margin-top: 10px;
}

.box {
  border: 6px solid #1588c9;
  border-radius: 15px;
  box-sizing: border-box;
  width: 33%;
  height: 100%;
}

.box:nth-child(1) {
}

.box:nth-child(2) {
  margin-left: 10px;
  margin-right: 10px;
}

.box .text {
  width: 100%;
  height: 20%;
  padding: 5px 5px 5px 5px;
  box-sizing: border-box;
  margin-top: -2px;
  font-size: 14px;
}

.box .bucket {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.contents:nth-child(3) {
  margin-top: 50px;
  flex-wrap: wrap;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
}
