.root {
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.author {
  margin-bottom: 10px;
  font-size: 22px;
  font-style: italic;
}

.subtitle {
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
}

.caption {
  margin-bottom: 20px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
}

.image {
  width: 30%;
  margin-left: 50px;
  margin-bottom: 10px;
}

.image img {
  width: 100%;
}

.box {
  border: 1px solid black;
  padding: 10px;
}

.box .subtitle {
  margin-top: 0px;
  margin-left: 0px;
}

.root li {
  margin-bottom: 10px;
}

.legend {
  margin-top: 10px;
}

.imageb {
  width: 90%;
  margin-left: 50px;
  margin-bottom: 10px;
}

.imageb img {
  width: 100%;
}

.line {
  width: 50%;
  height: 1px;
  background: black;
  margin-left: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
