.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}

.icon {
  width: 24px;
  height: 24px;
}

.value {
  font-size: 16px;
  font-weight: bold;
}
