.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
}

.dropdown {
  margin-bottom: 20px;
}
