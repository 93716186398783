.root {
  background: #f9f9f9;
  border-left: 4px solid #07758d;
  margin: 10px;
  padding: 5px 10px;
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}
