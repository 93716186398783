.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.header {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.grid {
  border: 3px solid black;
  width: 300px;
  height: 75px;
  display: column;
  padding: 20px;
  justify-content: center;
}

.row {
  display: flex;
  margin-left: 40px;
}

.row .image {
  width: 150px;
  margin-top: 40px;
  margin-left: 25px;
  margin-right: 25px;
  cursor: default;
}

.row img {
  width: 100%;
  cursor: default;
  pointer-events: none;
}

.item {
  font-size: 18px;
  color: #549bcf;
  cursor: pointer;
  height: 20px;
  margin-left: 75px;
}
