.root {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 20px;
}

.background {
  position: absolute;
}

.top {
  width: 100%;
  height: 250px;
  display: flex;
}

.top .text {
  font-size: 18px;
  width: 55%;
}

.top .image {
  width: 43%;
}

.top .image img {
  margin-top: -10px;
  width: 100%;
}

.bottom {
  position: relative;
}

.equation {
  width: 100%;
  height: 40px;
}

.equation:nth-child(1) {
  margin-bottom: -40px;
}

.equation.open:nth-child(1) {
  position: absolute;
  z-index: 99999;
  top: -50px;
}

.equation:nth-child(2) {
  margin-top: 50px;
}

.equation:nth-child(3) {
  margin-top: 15px;
}

.equation .text {
  font-size: 18px;
  line-height: 18px;
  margin-right: 5px;
}

.dropdown {
  display: inline-block;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.dropdown > div {
  transform: translateY(-2px);
}

.equation input {
  width: 50px;
  height: 20px;
  margin-top: -2px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0px solid transparent;
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  pointer-events: all;
  text-align: center;
}

.equation input:focus {
  outline: none;
}
