.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.text {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}
