.root {
}

.hextop {
  margin-top: 5px;
  width: 0;
  height: 0;
}

.hex {
  background-color: #eeeeee;
  position: relative;
}

.hexbottom {
  width: 0;
  margin-bottom: 20px;
}
