.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.top {
  display: flex;
  width: 100%;
  height: 100px;
}

.top .instructions {
  width: 100%;
  margin-right: 50px;
}

.top .instructions .text {
  font-size: 18px;
}

.top .instructions .text:first-child {
  margin-bottom: 10px;
}

.header {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.grid {
  margin-top: 10px;
  display: grid;
  grid-template-rows: 20% 20% 20% 20% 20%;
  grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  width: 100%;
  height: 175px;
  font-size: 22px;
}

.grid .icon {
  width: 100%;
  height: 100%;
  line-height: 40px;
  text-indent: 10px;
  box-sizing: content-box;
}

.bottom {
  margin-top: 5px;
  margin-left: -5px;
  width: 100%;
}

.bottom .image {
  width: 100%;
}
