.root {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
}

.root img {
  width: 100%;
}
