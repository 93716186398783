.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.header {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.grid {
  border: 2px solid black;
  background-color: #b0c4dd;
  width: 325px;
  height: 325px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
}

.large,
.medium,
.small {
  cursor: pointer;
}

.medium {
  margin-left: 3px;
  margin-right: 3px;
}

.small {
  height: 20px;
}
