.root {
  color: black;
  width: 32px;
  text-align: center;
  text-decoration: none;
  line-height: 24px;
  cursor: pointer;
  background: #fbfcfd;
  border: 4px solid #edeff0;
  border-radius: 50%;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 18px;
  box-sizing: border-box;
  margin-left: 10px;
}

.completed {
  border: 4px solid #7fb135;
}

.current {
  border: 4px solid #7fb135;
  background: #b8d293;
}
