.root {
  margin-left: auto;
  margin-right: auto;
  height: 20px;
  width: 150px;
  cursor: pointer;
  color: #549bcf;
  position: relative;
}

.contents {
  position: absolute;
  top: -5px;
  width: 100%;
}
