.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.header {
  display: flex;
}

.header .title {
  font-family: "Zilla Slab";
  font-size: 32px;
  font-weight: bold;
  color: #056b90;
  margin-top: -5px;
  margin-left: 10px;
}

.contents {
  margin-top: 10px;
  margin-left: 50px;
  width: 85%;
  font-size: 18px;
  font-family: "Open Sans";
}

.contents > div {
  margin-bottom: 20px;
}

.contents > ul {
  padding-inline-start: 20px;
  margin-block-start: 10px;
  list-style-type: disc;
}

.contents > ul > li {
  margin-left: 10px;
  margin-bottom: 10px;
}

.contents > ul > li::marker {
  color: #056b90;
  font-weight: bold;
}

.row {
  display: flex;
  justify-content: space-between;
}

.right {
  width: 45%;
}

.right img {
  border-radius: 5px;
}

.right > div:nth-child(1) {
  margin-bottom: 20px;
}

.right .detail {
  margin-bottom: 20px;
  text-align: left;
}
