.root {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.root img {
  width: 100%;
}
