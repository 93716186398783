.root {
  width: 100%;
  height: 72px;
  background-color: #ccecf2;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  position: relative;
}

.divider {
  width: 1px;
  height: 160%;
  background-color: white;
  margin-top: -10px;
}

.button {
  position: absolute;
  right: 10px;
  margin-top: -5px;
  min-width: 116px;
  height: 40px;
  background: #07758d;
  border-radius: 20px;
  vertical-align: middle;
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fbfcfd;
  padding: 0 10px;
  cursor: pointer;
}

.back {
  position: absolute;
  right: 150px;
  margin-top: -5px;
  min-width: 116px;
  height: 40px;
  background: white;
  border: 2px solid #07758d;
  border-radius: 20px;
  vertical-align: middle;
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #056b90;
  padding: 0 10px;
  cursor: pointer;
}
