.root {
  color: #282828;
}

.circle {
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid orange;
  position: absolute;
}
