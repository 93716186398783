.root {
  border: 2px solid #07758d;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  line-height: 25px;

  font-family: "Zilla Slab";
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #056b90;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  cursor: pointer;
}

.root.open {
  border: 2px solid #ccecf2;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  font-family: "Open Sans";
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: black;
}

.root > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
