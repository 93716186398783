.root {
  display: inline-flex;
  font-size: 18px;
  line-height: 18px;
}

.root > .text.hidden {
  color: rgba(0, 0, 0, 0);
  width: 2px;
}

.root .vertical {
  width: 10px;
  margin-top: -10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}

.root .vertical .text {
  text-align: center;
}

.root .line {
  width: 100%;
  height: 1px;
  background-color: black;
}
