.root {
  position: relative;
}

.grid {
  display: grid;
  grid-template-rows: 35% 35%;
  grid-template-columns: 35% 35%;
  gap: 20px;
  margin-left: 20%;
  margin-top: 100px;
}

.entry {
  display: flex;
}

.text {
  font-size: 18px;
  font-weight: bold;
  line-height: 190px;
  margin-right: 10px;
}

.bucket {
  width: 100px;
  height: 100px;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.bucket .contents {
  width: 67.5px;
  height: 67.5px;
}
