.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.linegraph {
  margin-top: 75px;
  margin-left: 170px;
}

.mask {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: red;
  z-index: 5;
}

.reset {
  z-index: 9999999999999999999999;
}
