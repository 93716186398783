.root {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 20px;
}

.top {
  width: 95%;
  height: 75px;
}

.top .text {
  font-size: 18px;
}

.equation {
  margin-top: 300px;
  width: 100%;
}

.equation .text {
  font-size: 18px;
  line-height: 18px;
  margin-right: 5px;
}

.inputs {
  width: 400px;
}

.inputs input {
  width: 75px;
  height: 20px;
  margin-top: -2px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0px solid transparent;
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  pointer-events: all;
  margin-top: 25px;
  margin-bottom: 25px;
}

.inputs input:focus {
  outline: none;
}

.contents {
  display: flex;
}

.contents .image {
  margin-top: -20px;
  width: 225px;
}

.contents .image img {
  width: 100%;
}
