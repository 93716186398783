.home {
  text-align: center;
}

.gameBackground {
  background: url(./assets/game-background.svg);
  background-size: cover;
  background-repeat: no-repeat;

  flex-grow: 5;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.9, 0.9);
  }

  100% {
    transform: scale(1, 1);
  }
}

.pulse {
  animation: pulse 1s ease-in-out infinite;
}

.root {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  background-color: #7edeee;
  font-family: "AvenirPrimary";
  display: flex;

  flex-direction: column;
}

.header {
  height: 64px;
  padding: 1px 24px;
  z-index: 999;
  position: relative;
  background: #22a7f0;
  box-sizing: border-box;
  align-items: center;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.header h1 {
  color: white;
  font-size: 24px;
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
}

.header .rightIcons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.header .rightIcons li {
  list-style: none;
}

.bottomToolbar {
  background-color: #efefef;
  min-width: 130px;
  height: 96px;
  border-radius: 96px;
  position: absolute;
  bottom: 10px;
  right: 40px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.background {
  background: url(./assets/lessonBackground.svg);
  background-size: 100% 100% !important;
  flex-grow: 5;
}

.gamesButton:active {
  position: relative;
  top: 4px;
}

.gamesButton {
  width: 72px;
  height: 80px;
  border: none;
  background-repeat: no-repeat;
  background-image: url(./assets/games.svg);
}

.resetButton {
  width: 72px;
  height: 80px;
  border: none;
  background-repeat: no-repeat;
  background-image: url(./assets/reset.svg);
}

.resetButton:hover {
  background-image: url(./assets/reset-hover.svg);
}

.resetButton:active {
  background-image: url(./assets/reset-down.svg);
}

.resetButton:disabled {
  background-image: url(./assets/reset-disabled.svg);
}

.checkButton {
  width: 72px;
  height: 80px;
  border: none;

  animation: pulse 1s ease-in-out infinite;

  background-repeat: no-repeat;
  background-image: url(./assets/check.svg);
}

.checkButton:hover {
  animation: none;
  background-image: url(./assets/check-hover.svg);
}

.checkButton:active {
  animation: none;
  background-image: url(./assets/check-down.svg);
}

.checkButton:disabled {
  animation: none;
  background-image: url(./assets/check-disabled.svg);
}

.continueButton {
  width: 72px;
  height: 80px;
  border: none;
  animation: pulse 1s ease-in-out infinite;
  background-repeat: no-repeat;
  background-image: url(./assets/continue.svg);
}

.continueButton:hover {
  animation: none;
  background-image: url(./assets/continue-hover.svg);
}

.continueButton:active {
  animation: none;
  background-image: url(./assets/continue-down.svg);
}

.continueButton:disabled {
  animation: none;
  background-image: url(./assets/check-disabled.svg);
}

.instructionButton {
  width: 72px;
  height: 80px;
  border: none;
  background-repeat: no-repeat;
  background-image: url(./assets/instruction.svg);
}

.instructionButton:hover {
  background-image: url(./assets/instruction-hover.svg);
}

.instructionButton:active {
  background-image: url(./assets/instruction-down.svg);
}

.instructionButton:disabled {
  background-image: url(./assets/instruction-disabled.svg);
}

.hintButton {
  width: 72px;
  height: 80px;
  border: none;
  background-repeat: no-repeat;
  background-image: url(./assets/hint.svg);
}

.hintButton:hover {
  background-image: url(./assets/hint-hover.svg);
}

.hintButton:active {
  background-image: url(./assets/hint-down.svg);
}

.hintButton:disabled {
  background-image: url(./assets/hint-disabled.svg);
}

.fox {
  position: absolute;
  bottom: 0px;
  left: 40px;
  width: 241px;
}

.shade {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}

.shadeClose {
  position: absolute;
  top: 0px;
  right: 5px;
  width: 56px;
  border-radius: 50%;
  margin: 16px 0 0 -72px;
  height: 56px;
  z-index: 101;
  cursor: pointer;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUESURBVHgB1Zs/aBtXHMd/ljMIaoOHDnFslBsMsU+FZCjYQ0JF2iFDijvYqTzVpTZ2u8hbcTu2HZypQ1soXlxoK5dIpdla8HABtbGgMUkIRAYnUUQSi1jBMnhQiIlz39O9RD6f7t6995ScPvCzxP2x7qv35/f7vfdTB6knatpRh+FYj+O6qm0104qmle1XpXSQGiBAM22EXgkSgYktmHadFCArEEJGbBMV1Qy0btE0w34vhKjAVgpzw6B6iwYWKiJw0LRzdHhMtRqIMyhg1+0McC1a6gOqi3sdreb2+YP26wPT9nhu4m1BtFaS6hNIGEBrLhFHl+VpQYibNO1tCg+sNTHb1rwu9BPIxL3u8cYDl0gvgWEWx/AV2elx4xSFWxyDBRm3yGXiaSYQs+UAtQ9dph0xbcN5wk3gKaoLbDf6qR7PVhoPRhwXoUsmqH055KOdAtF67TDumoFnH2k8EHGcTJAEsVgsCiNBhoeHe2TutzkQHzcKTJAgn01PH79dKHxy5+69L2GPtyqphYWFk7z3z8/Pn9iqVFK5f/9L4f77pdLM6OioaNTEEgGLxlBtjgS6Jx4kk/1zxu1cJnPJmEgmr3jd/3s6nRgfv/Ce27kvPp9dWlxcvE/BgU/8Hq9MIMbeRyQAvu1jx/qaftteIr3EgY2NjeLQ4IlfSIxl0wqsiw6SABgvXuLA2Nh4Ir28fEiEnzgwMDCgSYxJq5sygRoJ0NvXx/XhTpE84hRgLZ3A+2skmN/lr16tgh4Tv2shEp1mf3+/g1fc9vZ2tVQq1UgMa/ELkQy6p3BY1hmJ7Jw9+/47PNfqelyLx+MacfLTjz/8s7KyUiZxyhB4miRyvVwuV9GH4h16XNdIIdlM1pidncmTHLsQ+C5JRi/ZbKaoUiTEJZMXrpACIDBBCtZYVIlUKc5kDwLPkSJkRSoWB6KsBZUhKrIF4iwi1AL26TkFReQeHpS3oKgThwvRdd1syYxI7NkUCEQcqmQhVzZCaYHIKhPYRZKoCr8Ui7QcPdYypFasVceWCkVaAuHkhUO1hYsXT05NTStzNQyI3Hv2rGxGSk9InP+RD2pUX+AVYqvyJMUTbAO4AgTbY+NjXK396NHD8vFY7GcSZwnZBIJZROyBJxrkakHEvfJzfxCPSOSaWKfJ5/MiG6DWbnHEfiMTsfvidOITEx8bmUsZLqe+ubkpmi4V8Yc5+lUSALkalhW8rmkWofCIRBeVyAexX/FyZXuX6lnFEQrI01pt5/z5D0+5nfMLv/zCuk8nJ39bX1/fpeCgS/+FN0wgNi1Ydh+ItbW16s0bN9ZPnznT393dbflTZPnfffvN5bm51DW/+yHyra6uHX1IPxo1wTH0iq+/mr+cTqcfkBgF2w4sG+Kfz5FEVINJp7e3Nyo4KRBbYJLolgwsGVrP4NzCTlB7700AwzYLZzaByUa4JiUE4NkPVGE4BaJr/E3ti0GOBnLbH8T+GsZCP7UX6H0558FmCa9BLXb+imFFQodotoUNt4HtYFZ4E2ZYzYyrv/SqssB4hC8Js0jfgiC/Opkwi+SqduKpdAqjSMwPv5KiUi4AkfAvCOfe9OyK2RJxJleMGqTakE08+NZkqnpFYQH0KnFWGgLRglgkucggEtR60HtWbQsco8qWNLPKDI3Ul59ICWOoKkoHaFFMRBrJFaVjAoGoIkkIY6gU2IhG9XGKV/aTArefFTBBjSYtqpEXXcfFNckRs2oAAAAASUVORK5CYII=) no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUESURBVHgB1Zs/aBtXHMd/ljMIaoOHDnFslBsMsU+FZCjYQ0JF2iFDijvYqTzVpTZ2u8hbcTu2HZypQ1soXlxoK5dIpdla8HABtbGgMUkIRAYnUUQSi1jBMnhQiIlz39O9RD6f7t6995ScPvCzxP2x7qv35/f7vfdTB6knatpRh+FYj+O6qm0104qmle1XpXSQGiBAM22EXgkSgYktmHadFCArEEJGbBMV1Qy0btE0w34vhKjAVgpzw6B6iwYWKiJw0LRzdHhMtRqIMyhg1+0McC1a6gOqi3sdreb2+YP26wPT9nhu4m1BtFaS6hNIGEBrLhFHl+VpQYibNO1tCg+sNTHb1rwu9BPIxL3u8cYDl0gvgWEWx/AV2elx4xSFWxyDBRm3yGXiaSYQs+UAtQ9dph0xbcN5wk3gKaoLbDf6qR7PVhoPRhwXoUsmqH055KOdAtF67TDumoFnH2k8EHGcTJAEsVgsCiNBhoeHe2TutzkQHzcKTJAgn01PH79dKHxy5+69L2GPtyqphYWFk7z3z8/Pn9iqVFK5f/9L4f77pdLM6OioaNTEEgGLxlBtjgS6Jx4kk/1zxu1cJnPJmEgmr3jd/3s6nRgfv/Ce27kvPp9dWlxcvE/BgU/8Hq9MIMbeRyQAvu1jx/qaftteIr3EgY2NjeLQ4IlfSIxl0wqsiw6SABgvXuLA2Nh4Ir28fEiEnzgwMDCgSYxJq5sygRoJ0NvXx/XhTpE84hRgLZ3A+2skmN/lr16tgh4Tv2shEp1mf3+/g1fc9vZ2tVQq1UgMa/ELkQy6p3BY1hmJ7Jw9+/47PNfqelyLx+MacfLTjz/8s7KyUiZxyhB4miRyvVwuV9GH4h16XNdIIdlM1pidncmTHLsQ+C5JRi/ZbKaoUiTEJZMXrpACIDBBCtZYVIlUKc5kDwLPkSJkRSoWB6KsBZUhKrIF4iwi1AL26TkFReQeHpS3oKgThwvRdd1syYxI7NkUCEQcqmQhVzZCaYHIKhPYRZKoCr8Ui7QcPdYypFasVceWCkVaAuHkhUO1hYsXT05NTStzNQyI3Hv2rGxGSk9InP+RD2pUX+AVYqvyJMUTbAO4AgTbY+NjXK396NHD8vFY7GcSZwnZBIJZROyBJxrkakHEvfJzfxCPSOSaWKfJ5/MiG6DWbnHEfiMTsfvidOITEx8bmUsZLqe+ubkpmi4V8Yc5+lUSALkalhW8rmkWofCIRBeVyAexX/FyZXuX6lnFEQrI01pt5/z5D0+5nfMLv/zCuk8nJ39bX1/fpeCgS/+FN0wgNi1Ydh+ItbW16s0bN9ZPnznT393dbflTZPnfffvN5bm51DW/+yHyra6uHX1IPxo1wTH0iq+/mr+cTqcfkBgF2w4sG+Kfz5FEVINJp7e3Nyo4KRBbYJLolgwsGVrP4NzCTlB7700AwzYLZzaByUa4JiUE4NkPVGE4BaJr/E3ti0GOBnLbH8T+GsZCP7UX6H0558FmCa9BLXb+imFFQodotoUNt4HtYFZ4E2ZYzYyrv/SqssB4hC8Js0jfgiC/Opkwi+SqduKpdAqjSMwPv5KiUi4AkfAvCOfe9OyK2RJxJleMGqTakE08+NZkqnpFYQH0KnFWGgLRglgkucggEtR60HtWbQsco8qWNLPKDI3Ul59ICWOoKkoHaFFMRBrJFaVjAoGoIkkIY6gU2IhG9XGKV/aTArefFTBBjSYtqpEXXcfFNckRs2oAAAAASUVORK5CYII=);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  border: none;
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  width: 588px;
  padding-left: 60px;
  gap: 20px;
}

.dropdown {
  width: 240px;
}

.headerSpacer {
  flex-grow: 2;
}

.preload {
  display: none;
}

.gameList {
  position: fixed;
  top: 200px;
  right: 0px;
  left: 0px;
  overflow-x: visible;
  overflow-y: visible;
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-left: 30px;
}

.game img {
  margin-bottom: 10px;
}

.game {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  width: 284px;

  padding: 15px;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.game label {

  font-family: "AvenirPrimary";
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  margin-bottom: 15px;

}

.game label.players {
  font-family: "AvenirPrimary";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #5C6A79;
  position: relative;



  background: #C5D3E259;

  padding: 3px 10px;
  border-radius: 10px;

}

.audioIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-30px, -30px);
}


.videoBackdrop {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video video {
  width: 1024px;
  height: 590px;
  transform: translateY(-10px);
}

.video video::-webkit-media-controls-panel {
  display: flex !important;
  opacity: 1 !important;
}

.video .closeButton {
  position: absolute;
  z-index: 10001;
  top: 14px;
  right: 14px;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  cursor: pointer;
  background-color: #fff;


}

.video {
  background-color: #fff;
  width: 1040px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: relative;
}


.nextIcon {
  position: absolute;
  right: 20px;
  top: 550px;
}

.previousIcon {
  position: absolute;
  left: 20px;
  top: 550px;

}

.tokenContainer {
  display: inline-block;
  background-color: #dceef9;
  position: absolute;
  width: 330px;
  left: 50%;
  top: 65px;
  padding: 10px 5px;
  transform: translateX(-50%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameNote {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  top: 550px;
  left: 50%;
  transform: translate(-50%);

}