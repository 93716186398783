.root {
  background: #fbfcfd;
  border: 1px solid #71767a;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.root:hover {
  background: #337ab7;
}

.selected,
.selected:hover {
  background: #449dac;
}

.circle {
  border-radius: 50%;
}
