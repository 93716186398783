.root {
  padding: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.instructions {
  margin-top: 20px;
  font-size: 18px;
}

.grid {
  margin-left: 25px;
  margin-top: 40px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
}

.grid div {
  margin-bottom: 10px;
}
