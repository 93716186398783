.root {
}

.header {
  display: flex;
  justify-content: space-between;
}

.arrow {
  width: 15px;
  height: 10px;
}
