.root {
}

.title {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
}

.image {
  width: 60%;
}

.image img {
  width: 100%;
}
