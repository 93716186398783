.root {
  display: flex;
  background: #7fb135;
  border-radius: 20px;
  align-items: center;
  width: 58px;
  height: 32px;
  position: absolute;
  top: -4px;
  left: 80px;
}

.icon {
  margin-left: 6px;
}

.circle {
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: black;
}
