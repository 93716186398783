.root {
  align-items: center;
  min-height: 80px;
  background: #fbfcfd;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: 2px solid #fbfcfd;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  box-sizing: border-box;
}

.root:hover {
  border: 2px solid #07758d;
}

.header {
  font-family: Open Sans;
  font-size: 20px;
  display: flex;
  margin-left: 20px;
  margin-top: 25px;
}

.header .title {
  font-weight: bold;
  color: #056b90;
  margin-right: 10px;
}

.header .subtitle {
  color: black;
}

.description {
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  color: black;
}
