.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.image {
  width: 100%;
}

.image img {
  width: 100%;
}

.contents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
